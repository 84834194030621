import React, { useState, useEffect } from 'react';
import '../App.css';
import styled from 'styled-components';
import { Analytics } from '@vercel/analytics/react';
import dayjs from 'dayjs';
import 'primeicons/primeicons.css';

const AppContainer = styled.div`
  background-color: white;
`;

const HeroSection = styled.section`
  & {
    display: flex;
  }

  & > #info-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
  }

  & > #info-container > .top * {
    color: white;
  }

  & > #info-container > .top p {
    font-weight: 100;
  }

  & > #info-container > .top {
    margin: 45px;
  }

  @media (min-width: 800px) {
    & > #info-container {
      padding-top: 15px;
    }
  }

  & > div:nth-child(1) {
    height: auto;
    flex: 5;
    background: black;
  }

  & > div:nth-child(1) > img {
    width: 100%;
    height: auto;
  }

  & > div:nth-child(2) {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:nth-child(2) > h1 {
    font-size: 4.8rem;
    font-weight: 900;
    color: black;
    margin-bottom: 10px;
  }

  & > div:nth-child(2) > p {
    color: black;
    display: flex;
  }

  & > div:nth-child(2) > p > span:nth-child(1),
  & > div:nth-child(2) > p > span:nth-child(3) {
    font-weight: 900;
    font-size: 2.5rem;
  }

  & > div:nth-child(2) > p > span:nth-child(2) {
    margin: 0px;
    font-size:1rem;
  }

  & div#instructions.hidden {
    color: transparent;
    transition: color .5s;
    font-weight: normal;
  }

  & div#instructions {
    padding: 5px 15px;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }

  & div#instructions.hidden ::marker {
    color: transparent;
    font-weight: bold;
    transition: color .5s;
  }

  & div#instructions.visible ::marker {
    color: #8f8f8f;
    font-weight: bold;
    transition: color .5s;
  }

  & div#instructions.hidden a {
    color: transparent;
    text-decoration: none;
    transition: color .5s, border-color .5s;
    font-weight: bold;
    padding: 5px 15px;
    border: 1px solid;
    border-color: transparent;
    border-radius: 34px;
  }

  & div#instructions.hidden strike {
    color: transparent;
    transition: color .5s;
  }

  & div#instructions.visible strike {
    color: red;
    transition: color .5s;
  }

  & div#instructions.visible {
    color: white;
    transition: color .5s;
    font-weight: normal;
  }

  & div#instructions.visible a {
    color: #57c6ff;
    text-decoration: none;
    transition: color .5s, opacity .5s, border-color .5s;
    font-weight: bold;
    padding: 5px 15px;
    border: 1px solid;
    border-radius: 34px;
    opacity: 1;
    border-color: #57c6ff;
  }

  & div#instructions.visible a:hover {
    opacity: .7;
  }

  & div#qr-venmo {
    text-align: center;
  }

  & div#continue-to-venmo {
    text-align: right;
    padding: 10px 0px;
  }

  & div#instructions.visible div#qr-venmo {
    opacity: 1;
    transition: opacity .5s;
  }

  & div#instructions.hidden div#qr-venmo {
    opacity: 0;
    transition: opacity .5s;
  }

  @media (max-width: 800px) {
    & {
      height: 1171px;
      flex-direction: column
    }
  }

  @media (max-width: 1148px) {
    & > div:nth-child(2) > h1 {
      font-size: 3rem;
      font-weight: 900;
      color: black;
      margin-bottom: 10px;
    }

    & > div:nth-child(2) > p > span:nth-child(1),
    & > div:nth-child(2) > p > span:nth-child(3) {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 800px) {
    div#qr-venmo {
      display: none;
    }
  }

  @media (min-width: 801px) {
    div#continue-to-venmo {
      display: none;
    }
  }
`;

const TicketButton = styled.button`
  & {
    padding: 10px 36px;
    font-weight: 900;
    font-size: 1.25rem;
    border-radius: 38px;
    background: #eb3c93;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 1s;
    color: black !important;
  }

  &:hover {
    opacity: .7;
    transition: opacity 1s;
  }

  & > img {
    height: 55px;
    padding: 0px 10px 0px 0px;
  }

  @media (max-width: 1148px) {
    & > img {
      height: 32px;
      padding: 0px 7px 0px 0px;
    }

    & {
      font-size: 1rem;
    }
  }
`;


const handleToggleInstructions = () => {
  const instructions = document.querySelector('div#instructions');
  if(instructions.className === 'hidden') {
    instructions.className = 'visible';
  } else {
    instructions.className = 'hidden';
  }
}

// const danceCountdownStart = dayjs('2024-01-12 21:00:00');
const danceCountdownStart = dayjs('2024-01-12 21:00:00');

function Giddy() {
  debugger;
  const [countDown, setCountDown] = useState();
  const date = dayjs(new Date());
  const isRegularPrice = date >= dayjs('2025-02-20 00:00:01');
  const regularPrice = 15;
  const presalePrice = 10;
  const priceElement = isRegularPrice
    ? <><strong style={{fontSize: '1.4rem'}}>{`$${regularPrice}`}</strong></>
    : <><strike>{`$${regularPrice}`}</strike> <strong style={{fontSize: '1.4rem'}}>{`$${presalePrice}`}</strong></>;

  const ticketButtonText = isRegularPrice
    ? 'CLICK TO GET TICKETS'
    : 'GET DISCOUNTED TICKETS';

  useEffect(() => {
    document.title = 'The Giddy Valentines dance party for ages 11-14'
  }, []);

  function allAreLessThanZero(countDown) {
    debugger;
    return countDown.days < 0 && countDown.hours < 0 && countDown.minutes < 0 && countDown.seconds < 0
  }

  return (
    <AppContainer>
      <HeroSection style={{marginBottom: '75px'}}>
        <div><img src="/images/giddy-promo-image.jpg" /></div>
        <div id="info-container">
          <div className="top">
            <h2>Pull your heartstrings💕, commit to the beat, and join us for our first ever The Giddy Valentines Dance!</h2>
            <p>Hey, 11-14 year olds – get ready to shine in your coolest Valentine's outfits at Giddy Valentines Dance, hosted at Black Lights Ignite! This isn’t about neon lights, it’s about showing off your creative, heart-filled style and celebrating with friends. Dress up in your best red, pink, and glittery ensembles, and get set to dance, laugh, and make unforgettable memories on a night made just for you!</p>
          </div>
          <div className="important-points">
            <div className="info">
              <div>👗</div>
              <div>
                <strong>What to Wear?</strong> Rock your most dazzling Valentine's outfit and get ready to steal the spotlight
              </div>
            </div>
            <div className="info">
              <div>❤️️</div>
              <div>
                <strong>Age Group:</strong> It's for 11-14 year olds, but if you're 15 and want to join the fun with your friends, we’ve got you covered!
              </div>
            </div>
            <div className="info">
              <div>🎟️</div>
              <div>
                <strong>Tickets:</strong> Don’t wait – order your outfits and grab your tickets today!
              </div>
            </div>
            <div className="info">
              <div>📅</div>
              <div>
                <strong>February 20 - 8pm to 10pm</strong>
              </div>
            </div>
            <div className="info">
              <div>📍</div>
              <div>
                <strong>Blacklights Ignite, Naples</strong>
              </div>
            </div>
          </div>
          <div>
            <TicketButton onClick={handleToggleInstructions} href="#"><img alt="Scowl Logo" src="/images/scowl-logo.png" /> <span>{ticketButtonText}</span></TicketButton>
          </div>
          <div id="instructions" className="hidden">
            <p>Venmo Instructions</p>
            <p>
              <ol>
                <li>Go to "Payments/Requests".</li>
                <li>Select "Friend to Friend" for payment method.</li>
                <li>Pay {priceElement} for each ticket.</li>
                <li>In description, type "Giddy" and ticket qty.</li>
              </ol>
            </p>
            <p style={{textAlign:'center'}}>ALL SALES ARE FINAL 🙂</p>
            <div id="continue-to-venmo"><a target="_blank" rel="noreferrer" href="https://account.venmo.com/u/Jessica-JolleyGreene">Continue with Venmo</a></div>
            <div style={{textAlign: 'center'}} id="qr-venmo"><img alt="Venmo QR Code" style={{width:'160px'}} src="/images/qr-venmo.jpg" /></div>
          </div>
        </div>
      </HeroSection>
      <Analytics />
    </AppContainer>
  );
}

export default Giddy;
